import { Checkbox } from '@/components/base/ui/checkbox';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/base/ui/table';
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useMemo } from 'react';
import { DataTablePagination } from './controls/table-pagination';
import { DataTableToolbar } from './controls/table-toolbar';
import { DataTableFilterOptions } from './data-table-types';
import { ScrollArea, ScrollBar } from '../base/ui/scroll-area';
import { LoadingTable } from '../base/skeleton/loading-table';
import { DataTableColumnHeader } from './controls/table-column-header';
import { cn } from '@/lib/utils';

interface SelectionRows {
	selectedRowsKey: (string | number)[];
	onSelectedRowsKeyChange: (selectedRows: (string | number)[]) => void;
}

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData>[];
	toolBars?: React.ReactNode;
	data: TData[];
	keyExpr: keyof TData;
	searchKey?: keyof TData | Array<keyof TData>;
	onReload?: () => void;
	filterOptions?: DataTableFilterOptions<TData>[];
	selectionRows?: SelectionRows;
	isLoading?: boolean;
	isViewOptions?: boolean;
	isScrollable?: boolean;
	height?: string;
	hasBorder?: boolean;
}

const DataTable = <TData, TValue>({
	columns,
	data,
	keyExpr,
	searchKey,
	onReload,
	filterOptions,
	selectionRows,
	isLoading,
	isViewOptions = false,
	isScrollable = false,
	toolBars,
	height = 'h-full',
	hasBorder = false,
}: DataTableProps<TData, TValue>) => {
	const [rowSelection, setRowSelection] = React.useState({});
	const [columnVisibility, setColumnVisibility] =
		React.useState<VisibilityState>({});
	const [searchValue, setSearchValue] = React.useState('');
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	);
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const tableHeight = useMemo(() => {
		return height ? height : 'h-[300px]';
	}, [height]);
	const defColumns = useMemo(() => {
		const results: ColumnDef<TData, TValue>[] = [];
		if (selectionRows) {
			results.push({
				id: 'select',
				meta: { className: 'w-10' },
				header: ({ table }) => (
					<div className='flex justify-center'>
						<Checkbox
							checked={
								table.getIsAllPageRowsSelected() ||
								(table.getIsSomePageRowsSelected() && 'indeterminate')
							}
							onCheckedChange={(value) =>
								table.toggleAllPageRowsSelected(!!value)
							}
							aria-label='Chọn tất cả'
							className='translate-y-[2px]'
						/>
					</div>
				),
				cell: ({ row }) => (
					<div className='flex justify-center'>
						<Checkbox
							checked={row.getIsSelected()}
							onCheckedChange={(value) => row.toggleSelected(!!value)}
							aria-label='Chọn dòng'
							className='translate-y-[2px]'
						/>
					</div>
				),
				enableSorting: false,
				enableHiding: false,
			});
		}

		if (columns && columns.length > 0) {
			columns.forEach((column) => {
				results.push({
					...column,
					filterFn: (row, id, value) => {
						return value.includes(row.getValue(id));
					},
				});
			});
		}

		return results;
	}, [columns, selectionRows]);

	const dataFiltered = useMemo(() => {
		if (searchKey && searchValue && searchValue.length > 0) {
			if (Array.isArray(searchKey)) {
				return data.filter((item) => {
					const search = searchValue?.toLowerCase();
					return searchKey.some((key) =>
						String(item[key]).toLowerCase().includes(search)
					);
				});
			}
			return data.filter((item) => {
				const search = searchValue?.toLowerCase();
				return String(item[searchKey]).toLowerCase().includes(search);
			});
		}
		return data;
	}, [data, searchValue, searchKey]);

	const table = useReactTable({
		data: dataFiltered,
		columns: defColumns,
		state: {
			sorting,
			columnVisibility,
			rowSelection,
			columnFilters,
		},
		initialState: {
			pagination: { pageSize: 50, pageIndex: 0 },
		},
		enableRowSelection: true,
		enableGlobalFilter: false,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	useEffect(() => {
		const selectedRows =
			table.getFilteredSelectedRowModel().rows.map((item) => item.original) ||
			[];
		if (selectionRows)
			selectionRows.onSelectedRowsKeyChange(
				selectedRows.map((x) => x[keyExpr] as string | number)
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowSelection]);

	return (
		<>
			{isLoading ? (
				<LoadingTable columnCount={4} rowCount={5} />
			) : (
				<div className='w-full space-y-4'>
					<DataTableToolbar
						table={table}
						isViewOptions={isViewOptions}
						filterOptions={filterOptions}
						onReload={onReload}
						searchValue={searchValue}
						onSearchChange={(value) => {
							setSearchValue(value);
						}}
						searchKey={searchKey}
						toolBars={toolBars}
					/>

					<ScrollArea className={cn('pe-4', isScrollable && tableHeight)}>
						<div className='rounded-md border'>
							<ScrollArea className='overflow-x-auto'>
								<Table className='min-w-full table-auto'>
									<TableHeader className='sticky top-0 z-30'>
										{table.getHeaderGroups().map((headerGroup) => (
											<TableRow key={headerGroup.id}>
												{headerGroup.headers.map((header, index) => {
													const meta = header.column.columnDef.meta;
													return (
														<TableHead
															key={`${header.id}_${index}`}
															colSpan={header.colSpan}
															className={cn(hasBorder && 'border')}
															{...meta}
														>
															{header.isPlaceholder
																? null
																: flexRender(
																		header.column.columnDef.header,
																		header.getContext()
																	)}
														</TableHead>
													);
												})}
											</TableRow>
										))}
									</TableHeader>
									<TableBody>
										{table.getRowModel().rows?.length ? (
											table.getRowModel().rows.map((row) => (
												<TableRow
													className='text-xs'
													key={row.id}
													data-state={row.getIsSelected() && 'selected'}
												>
													{row.getVisibleCells().map((cell, index) => {
														const meta = cell.column.columnDef.meta;
														return (
															<TableCell
																key={`${cell.id}_${index}`}
																{...meta}
																className={cn(hasBorder && 'border')}
															>
																{flexRender(
																	cell.column.columnDef.cell,
																	cell.getContext()
																)}
															</TableCell>
														);
													})}
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell
													colSpan={columns.length + 1}
													className='h-24 text-center'
												>
													Không có dữ liệu.
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</ScrollArea>
							<ScrollBar orientation='horizontal' />
						</div>
					</ScrollArea>

					<DataTablePagination table={table} />
				</div>
			)}
		</>
	);
};

export default DataTable;
