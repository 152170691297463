import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { FormField } from '@/components/base/ui/form';
import { Separator } from '@/components/base/ui/separator';
import FromDateTimeRangePicker from '@/components/common/form/form-datetime-range-picker';
import FormInputNumber from '@/components/common/form/form-input-number';
import FormTextarea from '@/components/common/form/form-text-area';
import { ContractInfoFormModel } from '@/contexts/models/contract-management/contract';
import { cn } from '@/lib/utils';
import ContractTransportTypeRadio from '@/modules/contract-dispatch/form/transport-type-radio';
import { UseFormReturn } from 'react-hook-form';
import PackagingRequirementRadio from '../form/packaging-requirements-radio';

interface PaymentInfoCardProps {
	form: UseFormReturn<ContractInfoFormModel, any, undefined>;
	isLoading: boolean;
	contract: ContractInfoFormModel;
}

export const PaymentInfoCard = ({
	form,
	isLoading,
	contract,
}: PaymentInfoCardProps) => {


	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('px-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md'>
							Thông tin vận chuyển/đơn giá
						</CardTitle>
						<CardDescription className='italic'>
							Thông tin vận chuyển/đơn giá của hợp đồng
						</CardDescription>
					</div>
				</CardHeader>
				<CardContent className={cn('gap-2 pt-2', 'text-xxs')}>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'transport_type_code'}
							render={({ field }) => (
								<ContractTransportTypeRadio
									isRequired={true}
									name={field.name}
									value={field.value || 'SINGLE'}
									defaultValue={'SINGLE'}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Hình thức giao/nhận hàng'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_packaging_requirements'}
							render={({ field }) => (
								<PackagingRequirementRadio
									isRequired={true}
									name={field.name}
									value={field.value}
									defaultValue={'Hàng rời trên xe'}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Hình thức đóng gói'
								/>
							)}
						/>
					</div>
					<Separator className='my-4' />
					<div>
						<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
							<FormField
								control={form.control}
								name={'goods_quantity'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											form.setValue(
												'goods_amount',
												value * form.getValues('goods_price')
											);
											field.onChange(value);
										}}
										isDisabled={isLoading}
										label='Tổng số lượng'
									/>
								)}
							/>
							<FormField
								control={form.control}
								name={'goods_price'}
								render={({ field }) => (
									<FormInputNumber
										isRequired={true}
										name={field.name}
										value={field.value}
										onValueChange={(value) => {
											form.setValue(
												'goods_amount',
												value * form.getValues('goods_quantity')
											);
											field.onChange(value);
										}}
										isDisabled={isLoading}
										label='Đơn giá (VNĐ)'
									/>
								)}
							/>
						</div>
						<FormField
							control={form.control}
							name={'goods_amount'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Tổng thành tiền (VNĐ)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_amount_words'}
							render={({ field }) => (
								<FormTextarea
									isRequired={true}
									readOnly={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Tổng thành tiền bằng chữ'
								/>
							)}
						/>
					</div>
					<Separator className='my-4' />
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'goods_vat'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										form.setValue(
											'goods_vat_amount',
											(value * form.getValues('goods_amount')) / 100
										);
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='VAT (%)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_vat_amount'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Số tiền VAT'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 lg:space-x-2'>
						<FormField
							control={form.control}
							name={'goods_deposit'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={(value) => {
										form.setValue(
											'goods_deposit_amount',
											(value * form.getValues('goods_amount')) / 100
										);
										field.onChange(value);
									}}
									isDisabled={isLoading}
									label='Đặt cọc (%)'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={'goods_deposit_amount'}
							render={({ field }) => (
								<FormInputNumber
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Số tiền cọc'
								/>
							)}
						/>
					</div>
					<Separator className='my-4' />

					<FormField
						control={form.control}
						name={'goods_delivery_date_start'}
						render={({ field }) => (
							<FromDateTimeRangePicker
								isRequired={true}
								name_from={field.name}
								name_to={'goods_delivery_date_end'}
								value_from={field.value}
								value_to={form.getValues('goods_delivery_date_end')}
								onValueFromChange={field.onChange}
								onValueToChange={(value) => {
									form.setValue('goods_delivery_date_end', value || '');
								}}
								isDisabled={isLoading}
								label='Ngày giao/nhận hàng'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={'goods_delivery_address'}
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Địa chỉ giao/nhận hàng'
								rows={5}
							/>
						)}
					/>
				</CardContent>
			</Card>
		</>
	);
};
