import { LoadingTable } from '@/components/base/skeleton/loading-table';
import { Badge } from '@/components/base/ui/badge';
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import {
	ButtonAdd,
	ButtonDelete,
	ButtonEdit,
	ConfirmDialog,
} from '@/components/common';
import { ButtonCustom } from '@/components/common/button';
import {
	useContractDeliveryDeleteMutation,
	useLazyContractDeliveryGetAllByFilterQuery,
} from '@/contexts/api/contract-management/contract/contract-delivery-api';
import {
	ContractDeliveryFormDefault,
	ContractDeliveryFormModel,
} from '@/contexts/models/contract-management/contract/ContractDeliveryModel';
import { cn } from '@/lib/utils';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/ui/table';
import { NotifyHelper } from '@/utils/notify-helper';
import { formatDate } from 'date-fns';
import { RefreshCcwIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContractDeliveryForm } from './contract-delivery-detail-sheet';

interface ContractDeliveryCardProps {
	contract_details: ContractDeliveryFormModel[];
	current_price: number;
	contract_id: number;
	isLoading: boolean;
	onValueChange: (value: ContractDeliveryFormModel[]) => void;
}

type ContractDeliveryObjectFrom = {
	isShow: boolean;
	isEdit: boolean;
	objectData: ContractDeliveryFormModel;
};

const ContractDeliveryCard = ({
	contract_details,
	onValueChange,
	current_price,
	contract_id,
	isLoading,
}: ContractDeliveryCardProps) => {
	const [showForm, setShowForm] = useState<ContractDeliveryObjectFrom>({
		isEdit: false,
		isShow: false,
		objectData: ContractDeliveryFormDefault,
	});

	const [dataSource, setDataSource] = useState<ContractDeliveryFormModel[]>([]);

	const [showConfirm, setShowConfirm] = useState({
		isShow: false,
		objectId: 0,
		objectSortOrder: -1,
	});

	const [deleteContractDelivery] = useContractDeliveryDeleteMutation();

	const [
		getContractDeliveryByContract,
		{ isSuccess, isFetching, isLoading: isLoadData, data: contract_deliveries },
	] = useLazyContractDeliveryGetAllByFilterQuery();

	const tableLoading = isLoading || isLoadData || isFetching;

	useEffect(() => {
		if (contract_details && contract_details.length > 0) {
			setDataSource(contract_details);
		}
	}, [contract_details]);

	useEffect(() => {
		if (contract_deliveries && contract_deliveries.length > 0) {
			onValueChange(contract_deliveries);
		}
	}, [contract_deliveries, onValueChange]);

	const handleSave = useCallback(
		(data: ContractDeliveryFormModel) => {
			console.log({ data });
			if (data && data.address && data.begin_date && data.end_date) {
				const newList = [...dataSource];
				if (data.id > 0) {
					const index = newList.findIndex((item) => item.id === data.id);
					if (index > -1) {
						newList[index] = data;
						NotifyHelper.Success('Cập nhật thành công');
					}
				} else {
					newList.push({ ...data, sort_order: newList.length + 1 });
					NotifyHelper.Success('Thêm mới thành công');
				}

				const reOrders = newList.map((item, index) => ({
					...item,
					sort_order: index + 1,
				}));
				onValueChange(reOrders);
			} else {
				NotifyHelper.Error('Vui lòng nhập lại thông tin');
			}
		},
		[dataSource, onValueChange]
	);

	const handleDelete = useCallback(async () => {
		const deleteItem = dataSource.find(
			(item) => item.id === showConfirm.objectId
		);
		const newList = dataSource;
		if (showConfirm.objectSortOrder > -1) {
			newList.splice(showConfirm.objectSortOrder, 1);
		}

		if (deleteItem && deleteItem.id > 0) {
			await deleteContractDelivery(deleteItem.id).unwrap();
		}

		const reOrders = newList.map((item, index) => ({
			...item,
			sort_order: index + 1,
		}));
		onValueChange(reOrders);
	}, [dataSource, onValueChange, deleteContractDelivery, showConfirm]);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('p-4', 'flex-row  items-start flex  bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md space-x-1'>
							Danh sách các lần giao/nhận hàng
						</CardTitle>
					</div>
				</CardHeader>
				<CardContent className={cn('pt-2')}>
					<div className='rounded-md border'>
						{tableLoading ? (
							<LoadingTable columnCount={4} rowCount={5} />
						) : (
							<>
								<Table className=''>
									<TableHeader className='text-center'>
										<TableRow className='text-center'>
											<TableHead className='w-[60px] text-center'>
												STT
											</TableHead>
											<TableHead className='w-[300px] text-center'>
												Thông tin giao/nhận hàng
											</TableHead>
											<TableHead className='w-[60px] text-center'>
												{contract_id > 0 && (
													<>
														<ButtonCustom
															onClick={() =>
																getContractDeliveryByContract(contract_id)
															}
															size={'xs'}
															title='Làm mới'
														>
															<RefreshCcwIcon className='h-3 w-3' />
														</ButtonCustom>
													</>
												)}
											</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										{dataSource.map((item, index) => (
											<TableRow key={index}>
												<TableCell className='text-center font-semibold'>
													{item.sort_order}
												</TableCell>
												<TableCell className=''>
													<Badge variant={'secondary'} className='h-5 text-xxs'>
														{item.title}
													</Badge>
													<span className='px-1 text-xxs'>
														{formatDate(item.begin_date || '', 'dd/MM/yyyy')} -{' '}
														{formatDate(item.end_date || '', 'dd/MM/yyyy')}:{' '}
														<b>{item.address || 'Chưa xác định'}</b>
													</span>
													<p className='text-xxs'>
														Số lượng: - Dự kiến{' '}
														<b>
															{item.quantity <= 0
																? 'Chưa xác định'
																: `${item.quantity.toLocaleString('vi')} KG`}
														</b>{' '}
														- Thực tế{' '}
														<b>
															{item.quantity_confirm <= 0
																? 'Chưa xác định'
																: item.quantity_confirm.toLocaleString('vi')}
														</b>{' '}
													</p>
													<p className='text-xxs'>
														Đơn giá:{' '}
														<b>
															{item.price <= 0
																? 'Chưa xác định'
																: item.price.toLocaleString('vi')}
														</b>{' '}
														(VNĐ)
													</p>
												</TableCell>
												<TableCell className='font-semibold text-center'>
													<ButtonAdd
														variant={'icon'}
														onClick={() =>
															setShowForm({
																isShow: true,
																isEdit: false,
																objectData: {
																	...ContractDeliveryFormDefault,
																	price: current_price,
																},
															})
														}
													/>
													<ButtonEdit
														variant={'icon'}
														onClick={() =>
															setShowForm({
																isShow: true,
																isEdit: true,
																objectData: item,
															})
														}
													/>
													<ButtonDelete
														variant={'icon'}
														className='text-red-600 hover:text-red-400'
														onClick={() =>
															setShowConfirm({
																isShow: true,
																objectId: item.id,
																objectSortOrder: item.sort_order - 1,
															})
														}
													/>
												</TableCell>
											</TableRow>
										))}
										<TableRow>
											<TableCell
												colSpan={3}
												className={
													showForm.isShow ? 'text-left' : 'text-center'
												}
											>
												<ButtonAdd
													variant={'outline'}
													className='h-7'
													title='Tạo mới'
													onClick={() =>
														setShowForm({
															isShow: true,
															isEdit: false,
															objectData: {
																...ContractDeliveryFormDefault,
																price: current_price,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</>
						)}
					</div>
				</CardContent>
			</Card>
			{showConfirm.isShow && (
				<ConfirmDialog
					isOpen={showConfirm.isShow}
					onClose={() =>
						setShowConfirm({ isShow: false, objectId: 0, objectSortOrder: -1 })
					}
					onConfirm={() => handleDelete()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa nội dung này?`}
				/>
			)}
			{showForm.isShow && (
				<ContractDeliveryForm
					isOpen={showForm.isShow}
					onOpenChange={() => {
						setShowForm({
							isShow: false,
							isEdit: false,
							objectData: ContractDeliveryFormDefault,
						});
					}}
					contract_id={contract_id}
					onSubmitted={handleSave}
					detail={showForm.objectData}
				/>
			)}
		</>
	);
};

export default ContractDeliveryCard;
