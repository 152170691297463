import DataTable from '@/components/data-table';

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { useLazyContractInfoGetWordFileQuery } from '@/contexts/api/contract-management/contract/contract-info-api';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ContractPurchaseListCard } from '@/modules/contract/table/purchase-mobile-table';
import { IsMobile } from '@/utils/check-mobile';

import { Badge } from '@/components/base/ui/badge';
import { Button } from '@/components/base/ui/button';
import { ButtonCustom } from '@/components/common/button';
import { useContractDispatchGetAllQuery } from '@/contexts/api/contract-management/contract/contract-dispatch-api';
import {
	ContractDispatch,
	ContractDispatchFormDefault,
	ContractDispatchFormModel,
} from '@/contexts/models/contract-management/contract/ContractDispatchModel';
import { contractDispatchActions } from '@/contexts/reducers/contract-management/contract/contract-dispatch.slice';
import ContractShipmentListSheet from '@/modules/contract-shipment/contract-shipment-list-sheet';
import { ContractDocumentTypeBadge } from '@/modules/contract/form/contract-document-type-badge';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import {
	EllipsisIcon,
	FileArchiveIcon,
	TagIcon,
	TruckIcon,
} from 'lucide-react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContractDispatchDetailSheet } from '../contract-dispatch-detail-sheet';
import { ContractDispatchChangeStatusDialog } from '../form/contract-dispatch-change-status-dialog';
import { TransportStatusBadge } from '../form/transport-status-badge';

interface ITransportTableProps {
	contractId?: number;
}

interface IOpenShipments {
	isOpen: boolean;
	dispatchInfo?: ContractDispatch;
}

export const TransportTable = ({ contractId = 0 }: ITransportTableProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const title = 'đợt giao hàng';

	const [openShipments, setOpenShipments] = useState<IOpenShipments>({
		isOpen: false,
		dispatchInfo: undefined,
	});

	const {
		contractDispatches: contractDespatches,
		is_open_confirm,
		is_open_dialog,
		is_open_lock_confirm,
		is_open_unlock_confirm,
		selected_ids,
		detail,
		inserted_id,
		is_open_change_status,
	} = useAppSelector((state: AppState) => state.Contract.ContractDispatch);

	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractDispatchGetAllQuery(contractId);

	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractDispatchFormModel
	) => {
		dispatch(
			contractDispatchActions.ContractDispatchShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractDispatchActions.ContractDispatchSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractDispatchActions.ContractDispatchChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const dataLoading = isFetching || isLoading;

	const columns: ColumnDef<ContractDispatch>[] = [
		{
			accessorKey: 'contract_status_id',
			header: 'Trạng thái',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<TransportStatusBadge value={row.original.dispatch_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								{row.original.is_default == false && (
									<>
										<DropdownMenuItem>
											<ButtonCustom
												variant={'link'}
												icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
												onClick={() => {
													addEditHandle(true, row.original);
												}}
												className='text-black h-5'
												title='Chỉnh sửa'
											/>
										</DropdownMenuItem>
										<DropdownMenuSeparator />
									</>
								)}
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<TagIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											selectedIdsChange([row.original.id]);
											changeStatusHandle(true);
										}}
										className='text-black h-5'
										title='Đổi trạng thái'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										className='text-black h-5'
										icon={<TruckIcon className='h-3.5 w-3.5' />}
										onClick={() =>
											setOpenShipments({
												isOpen: true,
												dispatchInfo: row.original,
											})
										}
										title='Điều vận'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			id: 'title',
			header: 'Thông tin đợt giao hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-sm '>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								// to={`/hop-dong-mua-hang/chi-tiet/${row.original.id}`}
								onClick={() =>
									setOpenShipments({
										isOpen: true,
										dispatchInfo: row.original,
									})
								}
							>
								{row.original.is_default == false && (
									<>
										<Badge
											variant={'outline'}
											className='h-4 text-xxs text-blue-500'
										>
											Bổ sung
										</Badge>
									</>
								)}
								<ContractDocumentTypeBadge value={row.original.document_type} />
								<br></br>
								{row.original.title || 'N/A'}
							</span>
						</div>
						<div className='text-xxs'>
							Hàng hoá:{' '}
							<b>
								{row.original.goods_code} - {row.original.goods_name}
							</b>
						</div>
					</div>
				);
			},
		},
		{
			id: 'address',
			header: 'Thông tin giao/nhận hàng',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xxs'>
							<b>{formatDate(row.original.begin_date, 'dd/MM/yyyy')}</b> -{' '}
							<b>{formatDate(row.original.end_date, 'dd/MM/yyyy')}</b>
						</div>
						<div className='text-xxs'>
							Địa chỉ: <b>{row.original.address}</b>
						</div>
						<div className='text-xxs'>
							Quy cách: <b>{row.original.goods_packaging_requirements}</b>
						</div>
					</div>
				);
			},
		},
		{
			id: 'shipment_count',
			header: 'Tổng điều vận đã tạo',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						{row.original.shipment_count?.toLocaleString('vi')}
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_total_start',
			header(props) {
				return (
					<div className='text-right text-xxs'>
						Tổng lượng hàng <br></br> cân tại điểm nhận hàng (KG)
					</div>
				);
			},
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						{row.original.goods_quantity_total_start?.toLocaleString('vi')}
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_total_end',
			header(props) {
				return (
					<div className='text-right text-xxs'>
						Tổng lượng hàng <br></br> cân tại điểm giao hàng (KG)
					</div>
				);
			},
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						{row.original.goods_quantity_total_end?.toLocaleString('vi')}
					</div>
				);
			},
		},
	];

	return (
		<>
			{IsMobile() ? (
				<ContractPurchaseListCard />
			) : (
				<DataTable
					isLoading={dataLoading}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={['title', 'address']}
					onReload={usePrefetch}
					isScrollable={true}
					height='h-[400px]'
				/>
			)}
			{is_open_dialog && (
				<ContractDispatchDetailSheet
					title={title}
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={detail || ContractDispatchFormDefault}
				/>
			)}
			{openShipments.isOpen && (
				<>
					<ContractShipmentListSheet
						isOpen={openShipments.isOpen}
						onOpenChange={() => setOpenShipments({ isOpen: false })}
						dispatch_id={openShipments.dispatchInfo?.id || 0}
						sheetTitle={openShipments.dispatchInfo?.title || ''}
						dispatch_info={openShipments.dispatchInfo}
					/>
				</>
			)}
			{is_open_change_status && (
				<ContractDispatchChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
		</>
	);
};
