import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from '@/components/base/ui/card';
import { FormField } from '@/components/base/ui/form';
import { FormInput } from '@/components/common/form';
import { ContractInfoFormModel } from '@/contexts/models/contract-management/contract';

import FormTextarea from '@/components/common/form/form-text-area';
import { Supplier } from '@/contexts/models/contract-management/catalog';
import { cn } from '@/lib/utils';
import { PartnerCombobox } from '@/modules/catalog/partner/partner-combobox';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { BankInfoCard } from './bank-info-card';

interface SupplierInfoCardProps {
	form: UseFormReturn<ContractInfoFormModel, any, undefined>;
	isLoading: boolean;
	infoType: 'SELLER' | 'BUYER';
}

const initSupplierInfoData: Supplier = {
	id: 0,
	address: '',
	bank_info: '',
	code: '',
	is_active: true,
	name: '',
	phone_number: '',
	representative: '',
	representative_title: '',
	tin: '',
};

export const SupplierInfoCard = ({
	form,
	isLoading,
	infoType,
}: SupplierInfoCardProps) => {
	const isSeller = infoType === 'SELLER';
	const { setValue } = form;

	const changeDataValue = useCallback(
		(data?: Supplier) => {
			const selectedData = data || initSupplierInfoData;
			setValue(
				isSeller ? 'seller_company_name' : 'buyer_company_name',
				selectedData.name
			);
			setValue(
				isSeller ? 'seller_address' : 'buyer_address',
				selectedData.address
			);
			setValue(
				isSeller ? 'seller_phone_number' : 'buyer_phone_number',
				selectedData.phone_number
			);
			setValue(
				isSeller ? 'seller_tax_code' : 'buyer_tax_code',
				selectedData.tin
			);
			setValue(
				isSeller ? 'seller_representative_name' : 'buyer_representative_name',
				selectedData.representative
			);
			setValue(
				isSeller
					? 'seller_representative_position'
					: 'buyer_representative_position',
				selectedData.representative_title
			);
			setValue(
				isSeller ? 'seller_bank_account' : 'buyer_bank_account',
				selectedData.bank_info
			);
		},
		[isSeller, setValue]
	);

	return (
		<>
			<Card className='mb-2'>
				<CardHeader
					className={cn('px-4 py-2', 'flex flex-row items-start bg-muted/50')}
				>
					<div className='grid gap-0.5'>
						<CardTitle className='group flex items-center gap-2 text-md'>
							{isSeller ? 'Bên Bán' : 'Bên Mua'}
						</CardTitle>
						<CardDescription className='italic'>
							{isSeller
								? 'Thông tin giao dịch bên cung cấp dịch vụ, hàng hóa'
								: 'Thông tin bên sử dụng dịch vụ hoặc mua hàng hóa'}
						</CardDescription>
					</div>
					<div className='ml-auto flex items-center gap-1'>
						<FormField
							control={form.control}
							name='supplier_id'
							render={({ field }) => (
								<PartnerCombobox
									isRequired={true}
									name={field.name}
									defaultValue={0}
									value={field.value}
									onValueChange={(value) =>
										field.onChange(parseInt(value || '0'))
									}
									onDataChange={(data) => changeDataValue(data)}
									isDisabled={isLoading}
								/>
							)}
						/>
					</div>
				</CardHeader>
				<CardContent className={cn('gap-2 pt-2')}>
					<FormField
						control={form.control}
						name={isSeller ? 'seller_company_name' : 'buyer_company_name'}
						render={({ field }) => (
							<FormInput
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Tên công ty'
								classForm='col-span-3'
							/>
						)}
					/>
					<FormField
						control={form.control}
						name={isSeller ? 'seller_address' : 'buyer_address'}
						render={({ field }) => (
							<FormTextarea
								isRequired={true}
								name={field.name}
								value={field.value}
								onValueChange={field.onChange}
								isDisabled={isLoading}
								label='Địa chỉ công ty'
								classForm='col-span-3'
							/>
						)}
					/>
					<div className='sm:grid lg:grid-cols-2 space-x-2'>
						<FormField
							control={form.control}
							name={isSeller ? 'seller_phone_number' : 'buyer_phone_number'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Số điện thoại'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={isSeller ? 'seller_tax_code' : 'buyer_tax_code'}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Mã số thuế'
								/>
							)}
						/>
					</div>
					<div className='sm:grid lg:grid-cols-2 space-x-2'>
						<FormField
							control={form.control}
							name={
								isSeller
									? 'seller_representative_name'
									: 'buyer_representative_name'
							}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Người đại diện'
								/>
							)}
						/>
						<FormField
							control={form.control}
							name={
								isSeller
									? 'seller_representative_position'
									: 'buyer_representative_position'
							}
							render={({ field }) => (
								<FormInput
									isRequired={true}
									name={field.name}
									value={field.value}
									onValueChange={field.onChange}
									isDisabled={isLoading}
									label='Chức vụ'
								/>
							)}
						/>
					</div>
					<FormField
						control={form.control}
						name={isSeller ? 'seller_bank_account' : 'buyer_bank_account'}
						render={({ field }) => (
							<BankInfoCard
								array_string={field.value}
								onValueChange={field.onChange}
							/>
						)}
					/>
				</CardContent>
			</Card>
		</>
	);
};
