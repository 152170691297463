import { Button } from '@/components/base/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { ConfirmDialog } from '@/components/common';
import {
	ButtonAdd,
	ButtonCustom,
	ButtonDelete,
} from '@/components/common/button';
import { SheetDialog } from '@/components/common/sheet-dialog';
import DataTable from '@/components/data-table';
import {
	useContractPaymentDeleteMultipleMutation,
	useContractPaymentGetAllQuery,
} from '@/contexts/api/contract-management/contract/contract-payment-api';
import {
	ContractPayment,
	ContractPaymentFormDefault,
	ContractPaymentFormModel,
} from '@/contexts/models/contract-management/contract/ContractPayment';
import { contractPaymentActions } from '@/contexts/reducers/contract-management/contract/contract-payment.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { EllipsisIcon, FileArchiveIcon, TagIcon } from 'lucide-react';
import { useCallback } from 'react';
import { ContractPaymentChangeStatusDialog } from './form/contract-payment-change-status-dialog';
import { PaymentStatusBadge } from './form/payment-status-badge';
import { ContractPaymentDetailSheet } from './payment-detail-sheet';

interface ContractPaymentProps {
	contract_id: number;
	contract_code: string;
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
}

export const ContractPaymentListSheet = ({
	contract_id,
	contract_code,
	isOpen,
	onOpenChange,
}: ContractPaymentProps) => {
	const dispatch = useAppDispatch();
	const title = 'phiếu đề nghị thanh toán';
	const {
		contractPayments,
		is_open_confirm,
		is_open_dialog,
		is_open_lock_confirm,
		is_open_unlock_confirm,
		selected_ids,
		detail,
		inserted_id,
		is_open_change_status,
	} = useAppSelector((state: AppState) => state.Contract.ContractPayment);

	const { data, isFetching, isLoading, refetch } =
		useContractPaymentGetAllQuery(contract_id);
	const [deletesAction] = useContractPaymentDeleteMultipleMutation();

	const addEditHandle = (
		isOpen: boolean,
		detail?: ContractPaymentFormModel
	) => {
		dispatch(
			contractPaymentActions.ContractPaymentShowDetail({
				is_open_dialog: isOpen,
				detail: detail,
			})
		);
	};

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractPaymentActions.ContractPaymentShowConfirm({
				is_open_confirm: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractPaymentActions.ContractPaymentSelectedIdsChange({
				selected_ids: ids,
			})
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractPaymentActions.ContractShipmentChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const columns: ColumnDef<ContractPayment>[] = [
		{
			accessorKey: 'payment_status_id',
			header: 'Trạng thái',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<PaymentStatusBadge value={row.original.payment_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				return (
					<div className='text-center'>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchiveIcon className='h-3.5 w-3.5' />}
										onClick={() => {
											addEditHandle(
												true,
												row.original as ContractPaymentFormModel
											);
										}}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				);
			},
		},
		{
			accessorKey: 'contract_code',
			header: 'Hợp đồng',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						{row.original.contract_code}
					</div>
				);
			},
		},
		{
			id: 'payment_date',
			header: 'Thời gian thanh toán',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{formatDate(row.original.payment_date, 'dd/MM/yyyy')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'payment_name',
			header: 'Thông tin thanh toán',
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-sm'>
							<span
								className='font-semibold text-primary hover:underline hover:text-primary/90 hover:cursor-pointer'
								onClick={() =>
									addEditHandle(true, row.original as ContractPaymentFormModel)
								}
							>
								{row.original.payment_name}
							</span>
						</div>
					</div>
				);
			},
		},
		{
			id: 'payment_amount',
			header: () => {
				return <div className='text-right'>Thành tiền (VNĐ)</div>;
			},
			enableSorting: true,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.payment_amount.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			id: 'payment_notes',
			header: 'Ghi chú',
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-left text-xxs'>
						<div className='text-xs italic'>{row.original.payment_notes}</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<SheetDialog
				sheetTitle={`Danh sách ${title} của hợp đồng ${contract_code}`}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				hasScroll={true}
				width='w-full'
			>
				<DataTable
					toolBars={
						<>
							<ButtonAdd onClick={() => addEditHandle(true)} />
							<ButtonCustom
								title='Đổi trạng thái'
								icon={<TagIcon className='h-4 w-4' />}
								disabled={selected_ids.length <= 0}
								onClick={() => changeStatusHandle(true)}
							/>
							<ButtonDelete
								variant={'destructive'}
								title={`Xóa (${selected_ids.length})`}
								disabled={selected_ids.length <= 0}
								onClick={() => deleteMultiHandle(true)}
							/>
						</>
					}
					isLoading={isFetching || isLoading}
					data={data || []}
					columns={columns}
					keyExpr={'id'}
					searchKey={'payment_name'}
					onReload={refetch}
					selectionRows={{
						selectedRowsKey: selected_ids,
						onSelectedRowsKeyChange: (values) =>
							selectedIdsChange(values as number[]),
					}}
					isScrollable={true}
					height='h-[calc(100vh-14rem)]'
				/>
			</SheetDialog>
			{is_open_dialog && (
				<ContractPaymentDetailSheet
					contract_id={contract_id}
					title={title}
					contract_code=''
					isOpen={is_open_dialog}
					onOpenChange={() => addEditHandle(false)}
					detail={detail || ContractPaymentFormDefault}
				/>
			)}
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
			{is_open_change_status && (
				<ContractPaymentChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
		</>
	);
};
