import { useForwardedRef } from '@/lib/use-forwarded-ref';
import { CalendarIcon } from 'lucide-react';
import React, { useRef, useState } from 'react';
import {
	DateValue,
	useButton,
	useDateRangePicker,
	useInteractOutside,
} from 'react-aria';
import {
	DateRangePickerStateOptions,
	useDateRangePickerState,
} from 'react-stately';
import { Button } from './button';
import { CalendarRangeBox } from './calendar-range-box';
import { DateField } from './date-field';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from '@/lib/utils';

const DateTimeRangePicker = React.forwardRef<
	HTMLDivElement,
	DateRangePickerStateOptions<DateValue>
>((props, forwardedRef) => {
	const ref = useForwardedRef(forwardedRef);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);

	const [open, setOpen] = useState(false);

	const state = useDateRangePickerState(props);
	const {
		labelProps,
		groupProps,
		startFieldProps,
		endFieldProps,
		buttonProps: _buttonProps,
		dialogProps,
		calendarProps,
	} = useDateRangePicker(props, state, ref);

	const { buttonProps } = useButton(_buttonProps, buttonRef);
	useInteractOutside({
		ref: contentRef,
		onInteractOutside: (e) => {
			setOpen(false);
		},
	});

	return (
		<div className='relative inline-flex flex-col text-left'>
			<span {...labelProps}>{props.label}</span>
			<div
				{...groupProps}
				ref={ref}
				className={cn(
					groupProps.className,
					'flex items-center rounded-md ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-1'
				)}
			>
				<div className='inline-flex h-7 flex-1 items-center rounded-l-md border border-r-0 border-input bg-transparent px-4 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1'>
					<DateField
						className='rounded-none border-none'
						{...startFieldProps}
					/>
					<span aria-hidden='true' className='px-2'>
						–
					</span>
					<DateField className='rounded-none border-none' {...endFieldProps} />
				</div>

				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger asChild>
						<Button
							{...buttonProps}
							variant='outline'
							className='rounded-l-none'
							disabled={props.isDisabled}
							onClick={() => setOpen(true)}
						>
							<CalendarIcon className='h-4 w-4' />
						</Button>
					</PopoverTrigger>
					<PopoverContent ref={contentRef} className='w-full'>
						<div {...dialogProps} className='space-y-3'>
							<CalendarRangeBox {...calendarProps} />
						</div>
					</PopoverContent>
				</Popover>
			</div>
		</div>
	);
});

DateTimeRangePicker.displayName = 'DateTimeRangePicker';

export { DateTimeRangePicker };
