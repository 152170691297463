import { cn } from '@/lib/utils';
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from '@/ui/sheet';
import { IsMobile } from '@/utils/check-mobile';
import { ScrollArea } from '../base/ui/scroll-area';

interface SheetDialogProps {
	isOpen?: boolean;
	onOpenChange: (isOpen: boolean) => void;
	sheetTitle?: string;
	sheetDescription?: any;
	children?: React.ReactNode;
	footerBtn?: React.ReactNode;
	isDisabled?: boolean;
	width?: string;
	hasScroll?: boolean;
}

export function SheetDialog({
	sheetTitle,
	sheetDescription,
	isOpen,
	onOpenChange,
	children,
	footerBtn,
	width,
	hasScroll,
}: SheetDialogProps) {
	return (
		<Sheet open={isOpen} onOpenChange={onOpenChange}>
			<SheetContent
				className={cn(
					'w-1/3 xs:max-w-xs sm:max-w-none p-2',
					width,
					IsMobile() && 'w-full'
				)}
			>
				<SheetHeader className='mb-2'>
					<SheetTitle>{sheetTitle}</SheetTitle>
					{sheetDescription && (
						<SheetDescription>{sheetDescription}</SheetDescription>
					)}
				</SheetHeader>
				<div className='py-2'>
					{hasScroll === true ? (
						<ScrollArea>
							<div className='h-[100vh] pl-1 pr-3 py-2'>
								{children}
							</div>
						</ScrollArea>
					) : (
						<>{children}</>
					)}
				</div>
				<SheetFooter>{footerBtn}</SheetFooter>
			</SheetContent>
		</Sheet>
	);
}
