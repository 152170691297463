import { ButtonAdd, ButtonDelete, ConfirmDialog } from '@/components/common';
import { ButtonCustom } from '@/components/common/button';
import DataTable, { DataTableColumnHeader } from '@/components/data-table';
import { PageContainer } from '@/components/page-container';
import { PageBody } from '@/components/page-container/page-body';
import { PageHeader } from '@/components/page-container/page-header';
import {
	useContractInfoDeleteMultipleMutation,
	useContractInfoGetAllQuery,
	useLazyContractInfoGetWordFileQuery,
} from '@/contexts/api/contract-management/contract/contract-info-api';

import {
	ContractInfo,
	eDocumentType,
} from '@/contexts/models/contract-management/contract';

import { contractInfoActions } from '@/contexts/reducers/contract-management/contract/contract-info.slice';
import { AppState, useAppDispatch, useAppSelector } from '@/contexts/store';

import { Button } from '@/components/base/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/base/ui/dropdown-menu';
import { ColumnDef } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import {
	DollarSignIcon,
	EllipsisIcon,
	FileArchive,
	FileDownIcon,
	TagIcon,
	TicketPlusIcon,
	TruckIcon,
} from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ContractDispatchListSheet from '../contract-dispatch/contract-dispatch-list-sheet';
import { ContractPaymentListSheet } from '../payment/payment-list-sheet';
import { ContractQuotationListSheet } from '../quotation/quotation-list-sheet';
import { ContractSalesListByContractSheet } from './contract-list-by-contract-sheet';
import { ContractChangeStatusDialog } from './form/contract-change-status-dialog';
import { ContractStatusBadge } from './form/contract-status-badge';
import { ContractDocumentTypeBadge } from './form/contract-document-type-badge';

interface IContractListPageProps {
	title: string;
	document_type: string;
}

const ContractListPage = ({
	title = 'hợp đồng',
	document_type = '000',
}: IContractListPageProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const { is_open_confirm, is_open_change_status, selected_ids } =
		useAppSelector((state: AppState) => state.Contract.ContractInfo);

	const [openQuotation, setOpenQuotation] = useState({
		isOpen: false,
		contract_id: 0,
		contract_code: '',
	});

	const [openPaymentList, setOpenPaymentList] = useState({
		isOpen: false,
		contract_id: 0,
		contract_code: '',
	});

	const [openSaleList, setOpenSaleList] = useState({
		isOpen: false,
		contract_id: 0,
	});

	const [openTransportationList, setOpenTransportationList] = useState({
		isOpen: false,
		contract_id: 0,
		contract_code: '',
	});

	const {
		data,
		isFetching,
		isLoading,
		refetch: usePrefetch,
	} = useContractInfoGetAllQuery(document_type);

	const [downloadWord] = useLazyContractInfoGetWordFileQuery();

	const dataLoading = isFetching || isLoading;

	const [deletesAction] = useContractInfoDeleteMultipleMutation();

	const addEditHandle = (detail?: ContractInfo) => {
		return navigate(`${location.pathname}/chi-tiet/${detail?.id || 0}`, {
			state: { from: location },
		});
	};

	const deleteMultiHandle = (isOpen: boolean) => {
		dispatch(
			contractInfoActions.ContractInfoShowConfirm({ is_open_confirm: isOpen })
		);
	};

	const changeStatusHandle = (isOpen: boolean) => {
		dispatch(
			contractInfoActions.ContractInfoChangeStatus({
				is_open_change_status: isOpen,
			})
		);
	};

	const selectedIdsChange = (ids: number[]) => {
		dispatch(
			contractInfoActions.ContractInfoSelectedIdsChange({ selected_ids: ids })
		);
	};

	const deleteMultiConfirm = useCallback(async () => {
		if (selected_ids.length > 0) await deletesAction(selected_ids).unwrap();
	}, [deletesAction, selected_ids]);

	const dataSources = useMemo(() => {
		// return Array(1000).fill(data || []).flat();
		return data || [];
	}, [data]);

	const columns: ColumnDef<ContractInfo>[] = [
		{
			accessorKey: 'contract_date',
			header: 'Ngày',
			cell: ({ row }) => {
				return (
					<div className='text-center text-xxs'>
						<div className='text-xxs'>
							{formatDate(row.original.contract_date, 'dd/MM/yyyy')}
						</div>
						<ContractStatusBadge value={row.original.contract_status_id} />
					</div>
				);
			},
		},
		{
			id: 'action_button',
			header: '...',
			cell: ({ row }) => {
				let linkEdit = `/hop-dong-mua-hang/chi-tiet/${row.original.id}`;
				if (document_type == eDocumentType.HOP_DONG_BAN_HANG) {
					linkEdit = `/hop-dong-ban-hang/chi-tiet/${row.original.id}`;
				}
				return (
					<>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button size='icon' variant='outline' className='h-5 w-5'>
									<EllipsisIcon className='h-3.5 w-3.5' />
									<span className='sr-only'>More</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align='start'>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileArchive className='h-3.5 w-3.5' />}
										onClick={() => navigate(linkEdit)}
										className='text-black h-5'
										title='Chỉnh sửa'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<FileDownIcon className='h-3.5 w-3.5' />}
										onClick={() => downloadWord(row.original.id)}
										className='text-black h-5'
										title='Tải hợp đồng'
									/>
								</DropdownMenuItem>
								<DropdownMenuSeparator />
								{document_type == eDocumentType.HOP_DONG_MUA_HANG && (
									<>
										<DropdownMenuItem>
											<ButtonCustom
												variant={'link'}
												icon={<FileArchive className='h-3.5 w-3.5' />}
												className='text-black h-5'
												onClick={() =>
													setOpenQuotation({
														isOpen: true,
														contract_id: row.original.id,
														contract_code: row.original.contract_code,
													})
												}
												title='Báo giá'
											/>
										</DropdownMenuItem>
										<DropdownMenuItem>
											<ButtonCustom
												variant={'link'}
												className='text-black h-5'
												icon={<FileArchive className='h-3.5 w-3.5' />}
												onClick={() => {
													setOpenSaleList({
														isOpen: true,
														contract_id: row.original.id,
													});
												}}
												title='HĐ bán hàng'
											/>
										</DropdownMenuItem>
									</>
								)}

								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<TruckIcon className='h-3.5 w-3.5' />}
										className='text-black h-5'
										onClick={() => {
											setOpenTransportationList({
												isOpen: true,
												contract_id: row.original.id,
												contract_code: row.original.contract_code,
											});
										}}
										title='Đợt giao hàng'
									/>
								</DropdownMenuItem>
								<DropdownMenuItem>
									<ButtonCustom
										variant={'link'}
										icon={<DollarSignIcon className='h-3.5 w-3.5' />}
										className='text-black h-5'
										onClick={() => {
											setOpenPaymentList({
												isOpen: true,
												contract_id: row.original.id,
												contract_code: row.original.contract_code,
											});
										}}
										title='Tài chính'
									/>
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</>
				);
			},
		},
		{
			id: 'contract_code',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title='Thông tin hợp đồng' />
			),
			cell: ({ row }) => {
				let linkEdit = `/hop-dong-mua-hang/chi-tiet/${row.original.id}`;
				if (document_type == eDocumentType.HOP_DONG_BAN_HANG) {
					linkEdit = `/hop-dong-ban-hang/chi-tiet/${row.original.id}`;
				}
				return (
					<div className='text-left text-xxs'>
						<div className='text-xxs'>
							<Link
								className='font-semibold text-primary hover:underline hover:text-primary/90'
								to={linkEdit}
							>
								<ContractDocumentTypeBadge value={row.original.document_type} />
								{row.original.contract_code || 'N/A'}
							</Link>
						</div>
						<div className='text-xxs'>
							Hàng hóa: <b className='text-xxs'>{row.original.goods_name}</b>{' '}
						</div>
						{document_type == eDocumentType.HOP_DONG_MUA_HANG ? (
							<div className='text-xxs'>
								Bên bán: <b>{row.original.seller_company_name}</b>
							</div>
						) : (
							<div className='text-xxs'>
								Bên mua: <b>{row.original.buyer_company_name}</b>
							</div>
						)}
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_quantity',
			header: ({ column }) => <div className='text-right'>Số lượng (Kg)</div>,
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>{' '}
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_quantity_delivery',
			header: ({ column }) => (
				<div className='text-right'>
					Số lượng<br></br> đã giao/nhận (Kg)
				</div>
			),
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_quantity.toLocaleString('vi')}
							</code>{' '}
						</div>
					</div>
				);
			},
		},
		{
			id: 'goods_price',
			header: ({ column }) => <div className='text-right'>Đơn giá (VNĐ)</div>,
			enableSorting: false,
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_price.toLocaleString('vi')}
							</code>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'goods_amount',
			header: ({ column }) => (
				<div className='text-right'>Thành tiền (VNĐ)</div>
			),
			cell: ({ row }) => {
				return (
					<div className='text-right text-xxs'>
						<div className='text-sm'>
							<code className='text-xs'>
								{row.original.goods_amount.toLocaleString('vi')}
							</code>{' '}
						</div>
						{/* <div className='text-muted-foreground md:block'>
							<b>{row.original.goods_amount_words}</b>
						</div> */}
					</div>
				);
			},
		},
	];

	return (
		<>
			<PageContainer>
				<PageHeader
					pageTitle={`Quản lý ${title}`}
					pageIcon={{ icon: TicketPlusIcon }}
				>
					<ButtonAdd onClick={() => addEditHandle()} />
					<ButtonCustom
						title='Đổi trạng thái'
						icon={<TagIcon className='h-4 w-4' />}
						disabled={selected_ids.length <= 0}
						onClick={() => changeStatusHandle(true)}
					/>
					<ButtonDelete
						variant={'destructive'}
						title={`Xóa (${selected_ids.length})`}
						disabled={selected_ids.length <= 0}
						onClick={() => deleteMultiHandle(true)}
					/>
				</PageHeader>
				<PageBody>
					<DataTable
						isLoading={dataLoading}
						data={dataSources || []}
						columns={columns}
						keyExpr={'id'}
						searchKey={'contract_code'}
						onReload={usePrefetch}
						selectionRows={{
							selectedRowsKey: selected_ids,
							onSelectedRowsKeyChange: (values) => {
								const ids = values.map((v) => Number(v));
								selectedIdsChange(ids);
							},
						}}
						isScrollable={true}
						height='h-[calc(100vh-16rem)]'
					/>
				</PageBody>
			</PageContainer>
			{is_open_confirm && (
				<ConfirmDialog
					isOpen={is_open_confirm}
					onClose={() => deleteMultiHandle(false)}
					onConfirm={() => deleteMultiConfirm()}
					title='Thông báo'
					description={`Bạn có chắc chắn muốn xóa ${selected_ids.length} ${title} này không?`}
				/>
			)}
			{is_open_change_status && (
				<ContractChangeStatusDialog
					is_open={is_open_change_status}
					onOpenChange={changeStatusHandle}
					contract_ids={selected_ids}
				/>
			)}
			{openQuotation.isOpen && (
				<ContractQuotationListSheet
					isOpen={openQuotation.isOpen}
					contract_id={openQuotation.contract_id}
					contract_code={openQuotation.contract_code}
					onOpenChange={() =>
						setOpenQuotation({
							isOpen: false,
							contract_id: 0,
							contract_code: '',
						})
					}
				/>
			)}
			{openPaymentList.isOpen && (
				<ContractPaymentListSheet
					isOpen={openPaymentList.isOpen}
					contract_id={openPaymentList.contract_id}
					contract_code={openPaymentList.contract_code}
					onOpenChange={() =>
						setOpenPaymentList({
							isOpen: false,
							contract_id: 0,
							contract_code: '',
						})
					}
				/>
			)}
			{openSaleList.isOpen && (
				<ContractSalesListByContractSheet
					isOpen={openSaleList.isOpen}
					contract_id={openSaleList.contract_id}
					document_type={eDocumentType.HOP_DONG_BAN_HANG}
					title='hợp đồng bán hàng'
					onOpenChange={() =>
						setOpenSaleList({
							isOpen: false,
							contract_id: 0,
						})
					}
				/>
			)}
			{openTransportationList.isOpen && (
				<ContractDispatchListSheet
					isOpen={openTransportationList.isOpen}
					contract_id={openTransportationList.contract_id}
					sheetTitle={`của hợp đồng bán hàng ${openTransportationList.contract_code}`}
					onOpenChange={() =>
						setOpenTransportationList({
							isOpen: false,
							contract_id: 0,
							contract_code: '',
						})
					}
				/>
			)}
		</>
	);
};

export default ContractListPage;
