import { DateSegment } from './date-segment';
import { createCalendar } from '@internationalized/date';
import { useRef } from 'react';
import {
	AriaDatePickerProps,
	DateValue,
	useDateField,
	useLocale,
} from 'react-aria';
import { useDateFieldState } from 'react-stately';
import { cn } from '@/lib/utils';
import { createUUID } from '@/lib/create-uuid';

interface DateFieldProps extends AriaDatePickerProps<DateValue> {
	className?: string;
}

function DateField(props: DateFieldProps) {
	const ref = useRef<HTMLDivElement | null>(null);

	const { locale } = useLocale();
	const state = useDateFieldState({
		...props,
		locale,
		createCalendar,
	});
	const { fieldProps } = useDateField(props, state, ref);

	return (
		<div
			{...fieldProps}
			id={`${props.name}-field-${createUUID()}`}
			ref={ref}
			
			className={cn(
				'inline-flex h-7 flex-1 items-center rounded-l-md border border-r-0 border-input bg-transparent py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1',
				props.isDisabled ? 'cursor-not-allowed opacity-50' : '',
				props.className
			)}
		>
			{state.segments.map((segment, i) => (
				<DateSegment key={i} segment={segment} state={state} />
			))}
			{state.isInvalid && <span >🚫</span>}
		</div>
	);
}

export { DateField };
